var prodcat = prodcat || {};

prodcat.data = prodcat.data || {};

(function ($) {
  Drupal.behaviors.stickyAddToBagV1 = {
    attach: function (context) {
      var $product = $('.js-spp-content .js-product', context);
      var skuBaseId = $product.data('sku-base-id');
      var prod = $product ? prodcat.data.getProduct($product.data('product-id')) : null;
      var sku = prodcat.data.getSku(skuBaseId);
      var $sticky = $('.js-sticky-add-to-bag', context);
      var $select = $('.js-sku-menu', $sticky);
      var $selectBox = new SelectBox($select, settings = { keepInViewport: true });
      var $productCtaContainer = $product.find('.js-product-full-cta');
      var $stickySkuSelect = $('.js-sticky-add-to-bag__sku-select-menu', $sticky);
      var isCr24Disabled = Drupal?.settings?.globals_variables?.disable_cr24;
      var $stickyImage = $('.js-sticky-add-to-bag__image', $product);
      var stickyImageSrc = sku?.LARGE_IMAGE || sku?.IMAGE_LARGE;

      $stickySkuSelect.removeClass('hidden');

      if (isCr24Disabled) {
        $(window).on('scroll', _.throttle(function () {
          $selectBox.hideMenus();
        }, 150));

        if ($stickyImage.length) {
          $('.js-sticky-add-to-bag__image', $product).attr('src', stickyImageSrc[0]);
        }
      } else {
        function stickyCarousel() {
          var productCtaContainerBoundTop = $productCtaContainer[0]?.getBoundingClientRect()?.top;

          if (productCtaContainerBoundTop >= '65') {
            // 65 sticky-add-to-bag optimum height
            $sticky.toggleClass('sticky-add-to-bag--show', false);
          } else {
            $sticky.toggleClass('sticky-add-to-bag--show', true);
          }
        }

        $(window).on('scroll', _.throttle(function () {
          $selectBox.hideMenus();
          stickyCarousel();
        }, 150));
      }

      // sticky Add To Bag bar
      $(document).on('product.skuSelect', '.js-product', function (event, skuBaseId) {
        $selectBox.setValue(skuBaseId);

        var $product = $(this);
        var prod = $product ? prodcat.data.getProduct($product.data('product-id')) : null;
        var sku = prodcat.data.getSku(skuBaseId);
        var $stickyImage = $('.js-sticky-add-to-bag__image', $product);
        var $stickySkuSelect = $('.js-sticky-add-to-bag__sku-select-menu', $product);
        var stickyImageSrc = sku?.LARGE_IMAGE || sku?.IMAGE_LARGE;

        if ($stickySkuSelect.length) {
          $stickySkuSelect.removeClass('hidden');
        }
        if ($stickyImage.length && sku !== null && isCr24Disabled) {
          $('.js-sticky-add-to-bag__image', $product).attr('src', stickyImageSrc[0]);
        }
      });
    }
  };
})(jQuery);
